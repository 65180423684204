.rdrSelected {
  background-color: transparent !important;
  background-image: linear-gradient(135deg, #5A46FB 0%, #1E95FF 100%) !important;
}

.rdrDayToday .rdrDayNumber span:after {
  display: none;
}
.rdrDayToday .rdrDayNumber span {
  color: #2648e8;
}

.rdrDayStartPreview.rdrDayEndPreview {
  color: #637381 !important;
}

.rdrDayNumber::after {
  color: #637381;
}

.rdrDayToday .rdrDayNumber span:after {
  background: #2648e8;
}

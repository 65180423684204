* {
  font-family: public sans;
}
.MuiClockPointer-root,
.MuiClock-pin {
  background-color: rgba(38, 72, 232, 1);
}
.MuiClockPointer-thumb {
  border: 16px solid rgba(38, 72, 232, 1);
}

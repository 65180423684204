.react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    /*border: 1px solid #a0a096;*/
    font-family: Public Sans;
    line-height: 1.125em;
}

.react-calendar--doubleView {
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
    font-family: Public Sans;
}

.react-calendar button:enabled:hover {
    /*cursor: pointer;*/
}

.react-calendar__navigation {
    display: flex;
    height: 44px;
    /*margin-bottom: 1em;*/
}

.react-calendar__navigation button {
    min-width: 44px;
    background: none;
    text-align: left;
    font-weight: bold;
    color: black;
}
/*media query for mobile*/
@media (max-width: 900px) {
    .react-calendar {
        width: 100%;
    }
    .react-calendar__navigation button {
        text-align: center;
        text-transform: capitalize;
    }
}

.react-calendar__navigation button.react-calendar__navigation__arrow {
    font-size: 2em;
    cursor: pointer;
}
.react-calendar__navigation button:disabled {
    /*background-color: #f0f0f0;*/
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    /*background-color: #e6e6e6;*/
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
    color:#637381;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
    /*color: #d10000;*/
}

/*color de  los dias del mes anterior y siguiente que aparecen en el mes actual*/
.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
    color: #757575;
    /*pointer-events: none;*/
    /*opacity: 0;*/
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}

.react-calendar__tile {
    max-width: 100%;
    padding: 8px;
    background: none;
    text-align: center;
    line-height: 16px;
    font: inherit;
    font-size: 0.833em;
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    margin: 2px;
    transform: scale(0.9);
}

/*.react-calendar__tile abbr {*/
/*    display: none;*/
/*    !*text-decoration: none;*!*/
/*    !*width: 0;*!*/
/*    !*height: 0;*!*/
/*    !*color: transparent;*!*/
/*    !*font-size: 0;*!*/
/*}*/

.react-calendar__tile:disabled {
    background-color: #f0f0f0;
    color: #ababab;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
    color: #cdcdcd;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__tile--now {
    /*background: #ffff76;*/
    background: #b2b2b2;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #a4a4a4;
}

.react-calendar__tile--hasActive {
    background: #76baff;
    cursor: pointer;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}

/**
removed because we don't want the active date to be highlighted on year view
.react-calendar__tile--active {
    background: #006edc;
    color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #1087ff;
}
 */

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}

.react-calendar__tile.event {
    color: white;
    background: red;
}
.react-calendar__tile.event:enabled:hover,
.react-calendar__tile.event:enabled:focus {
    background: #d10000;
}

/**
COLORS++++++++++++++++++++++++++++++++
 */

.react-calendar__tile.event-2648E8 {
    color: white;
    background: #2648E8;
}
.react-calendar__tile.event-2648E8:enabled:hover,
.react-calendar__tile.event-2648E8:enabled:focus {
    background: #1327A7;
}


.react-calendar__tile.event-7B95F8 {
    color: white;
    background: #7B95F8;
}
.react-calendar__tile.event-7B95F8:enabled:hover,
.react-calendar__tile.event-7B95F8:enabled:focus {
    background: #2648E8;
}


.react-calendar__tile.event-07116F {
    color: white;
    background: #07116F;
}
.react-calendar__tile.event-07116F:enabled:hover,
.react-calendar__tile.event-07116F:enabled:focus {
    background: #7B95F8;
}


.react-calendar__tile.event-1E95FF {
    color: white;
    background: #1E95FF;
}
.react-calendar__tile.event-1E95FF:enabled:hover,
.react-calendar__tile.event-1E95FF:enabled:focus {
    background: #0F56B7;
}


.react-calendar__tile.event-78CDFF {
    color: white;
    background: #78CDFF;
}
.react-calendar__tile.event-78CDFF:enabled:hover,
.react-calendar__tile.event-78CDFF:enabled:focus {
    background: #1E95FF;
}


.react-calendar__tile.event-0F56B7 {
    color: white;
    background: #0F56B7;
}
.react-calendar__tile.event-0F56B7:enabled:hover,
.react-calendar__tile.event-0F56B7:enabled:focus {
    background: #D2F3FF;
}


.react-calendar__tile.event-5A46FB {
    color: white;
    background: #5A46FB;
}
.react-calendar__tile.event-5A46FB:enabled:hover,
.react-calendar__tile.event-5A46FB:enabled:focus {
    background: #2F23B4;
}


.react-calendar__tile.event-9E8FFD {
    color: white;
    background: #9E8FFD;
}
.react-calendar__tile.event-9E8FFD:enabled:hover,
.react-calendar__tile.event-9E8FFD:enabled:focus {
    background: #5A46FB;
}


.react-calendar__tile.event-2F23B4 {
    color: white;
    background: #2F23B4;
}
.react-calendar__tile.event-2F23B4:enabled:hover,
.react-calendar__tile.event-2F23B4:enabled:focus {
    background: #9E8FFD;
}


.react-calendar__tile.event-0FCEA9 {
    color: white;
    background: #0FCEA9;
}
.react-calendar__tile.event-0FCEA9:enabled:hover,
.react-calendar__tile.event-0FCEA9:enabled:focus {
    background: #068B8B;
}


.react-calendar__tile.event-71FAB7 {
    color: white;
    background: #71FAB7;
}
.react-calendar__tile.event-71FAB7:enabled:hover,
.react-calendar__tile.event-71FAB7:enabled:focus {
    background: #068B8B;
}


.react-calendar__tile.event-068B8B {
    color: white;
    background: #068B8B;
}
.react-calendar__tile.event-068B8B:enabled:hover,
.react-calendar__tile.event-068B8B:enabled:focus {
    background: #71FAB7;
}


.react-calendar__tile.event-FFAB00 {
    color: white;
    background: #FFAB00;
}
.react-calendar__tile.event-FFAB00:enabled:hover,
.react-calendar__tile.event-FFAB00:enabled:focus {
    background: #B77119;
}


.react-calendar__tile.event-FFDB83 {
    color: white;
    background: #FFDB83;
}
.react-calendar__tile.event-FFDB83:enabled:hover,
.react-calendar__tile.event-FFDB83:enabled:focus {
    background: #FFAB00;
}


.react-calendar__tile.event-7A4009 {
    color: white;
    background: #7A4009;
}
.react-calendar__tile.event-7A4009:enabled:hover,
.react-calendar__tile.event-7A4009:enabled:focus {
    background: #FFDB83;
}


.react-calendar__tile.event-F95C5E {
    color: white;
    background: #F95C5E;
}
.react-calendar__tile.event-F95C5E:enabled:hover,
.react-calendar__tile.event-F95C5E:enabled:focus {
    background: #B32E47;
}


.react-calendar__tile.event-FDAC9D {
    color: white;
    background: #FDAC9D;
}
.react-calendar__tile.event-FDAC9D:enabled:hover,
.react-calendar__tile.event-FDAC9D:enabled:focus {
    background: #F95C5E;
}


.react-calendar__tile.event-771136 {
    color: white;
    background: #771136;
}
.react-calendar__tile.event-771136:enabled:hover,
.react-calendar__tile.event-771136:enabled:focus {
    background: #FDAC9D;
}

.calendar-sidebar-event-delete-button-stack {
    display: none;
}

/** media query for md and above */
@media (min-width: 768px) {
    .calendar-sidebar-event-card:hover .calendar-sidebar-event-delete-button-stack {
        display: block;
    }
}

@media (max-width: 768px) {
    .calendar-sidebar-event-card .calendar-sidebar-event-delete-button-stack {
        display: block;
    }
}